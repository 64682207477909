import React, {
  PropsWithChildren,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { Project, WorkspaceDetails } from "../../../state/workspaces/types";
import * as workspaceActions from "../../../state/workspaces/workspaces.actions";
import { WorkspaceType } from "../../../state/workspaces/WorkspaceType";
import { DashboardOption } from "../../../state/workspaces/DashboardOption";
import {
  getSelectedWorkspaceDetails,
  isWorkspaceLimited,
} from "../../../state/workspaces/workspaces.selector";
import { Localized } from "../../../strings";
import { compareDeeply } from "../../../utils/comparators";
import LazyLoad from "react-lazy-load";
import useAutoVisibility from "../../hooks/useAutoVisibility";
import hasNewTabInput from "../../../utils/hasNewTabInput";
import { isElectron } from "../../../utils/is.electron";
import { getProjectFromIdFillingUnkownOnes } from "../../../state/workspaces/projects.selector";
import { enableWorkspaceExport } from "../../../state/config/config.selector";
import { FrameContexts } from "@microsoft/teams-js";
import { mapName } from "../../CreateWorkspaceSection";
import { LicenseType } from "../../../state/user/user.actions";
import { ProjectStatuses } from "../../../state/workspaces/workspaces.reducer";
import LiveWorkspacePreview from "./LiveWorkspacePreview";
import CustomWorkspacePreview from "./CustomWorkspacePreview";
import DefaultWorkspacePreview from "./DefaultWorkspacePreview";
import WorkspaceCardThumbnail from "./WorkspaceCardThumbnail";
import { MoreOptions } from "../../MoreOptions/MoreOptions";
import Styles from "./WorkspaceCard.module.css";

const shouldRerender = (
  prevProps: PropsWithChildren<WorkspaceCardProps>,
  nextProps: PropsWithChildren<WorkspaceCardProps>
) => {
  return compareDeeply(nextProps, prevProps);
};

export const WorkspaceCard: React.FC<WorkspaceCardProps> = React.memo(
  (props) => {
    const {
      workspaceDetails,
      openWorkspace,
      updateWorkspaceName,
      canEditLabels,
      isOptionsMenuOpen,
      closeOptionsMenu,
      unhideWorkspace,
      toggleOptionsMenu,
      showShareModal,
      showInfoModal,
      showAssignProjectModal,
      showRemoveFromProjectPrompt,
      showHideWorkspacePrompt,
      showLeaveWorkspacePrompt,
      showDeleteWorkspacePrompt,
      showEditLabelPrompt,
      showDuplicateWorkspacePrompt,
      showCreateFromTemplatePrompt,
      showDownloadModal,
      strings,
      showProjectOptions,
      project,
      hasAssignableProjects,
      isWorkspaceExportEnabled,
      config,
      isFavorite = false,
    } = props;
    const allowFullScreen = useMemo(
      () =>
        workspaceDetails.isFullscreen &&
        config.teamsFrame != FrameContexts.sidePanel,
      [workspaceDetails.isFullscreen, config.teamsFrame]
    );
    const applyOpacityStyle =
      project?.status === ProjectStatuses.Inactive &&
      !workspaceDetails.isFullscreen;
    const isTemplate = !!workspaceDetails.templateId;
    const isLimited = useSelector((state: RootState) =>
      isWorkspaceLimited(state, workspaceDetails.workspaceId)
    );
    let [isEditingWorkspaceName, toggleEditingName] = useState(false);

    //This toggle is to prevent immediate open after name change, since it cause nasty race condition between document metadata change and card live mode
    //We want to allow update cycle to end before we open the workspace (See #12356)
    const isUpdating = useSelector(
      (state: RootState) => state.context.workspaces.isUpdatingWorkspace,
      shallowEqual
    );
    let canOpen = useMemo(() => !isEditingWorkspaceName && !isUpdating, [
      isEditingWorkspaceName,
      isUpdating,
    ]);

    const [isVisible, setIsVisible] = useState(false);
    const {
      ref,
      outsideElementRef,
      componentVisible,
      setComponentVisible,
    } = useAutoVisibility(false);
    useEffect(() => {
      if (!componentVisible && isOptionsMenuOpen) {
        closeOptionsMenu();
      }
    }, [componentVisible]);

    const openWorkspaceLink = (e: React.MouseEvent) => {
      e.preventDefault();
      if (!canOpen) return;
      // only left click and middle click can be used to open workspace
      if ([0, 1].includes(e.button)) {
        if (isTemplate) {
          showCreateFromTemplatePrompt();
          return;
        }
        // workspace will open in a new tab if holding action button or using middle click
        openWorkspace(workspaceDetails, hasNewTabInput(e) && !isElectron());
      }
    };

    const openMoreOptions = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      const optionType = isFavorite
        ? DashboardOption.OPTIONS_FAVORITE
        : DashboardOption.OPTIONS;

      e.stopPropagation();
      setComponentVisible(true);
      toggleOptionsMenu(optionType);
    };

    let showWorkspaceModifiedNotification =
      workspaceDetails.lastAccess &&
      workspaceDetails.lastModified &&
      workspaceDetails.lastModified
        ? workspaceDetails.lastAccess < workspaceDetails.lastModified
        : false;

    const onEditLabelClicked = useCallback(() => {
      if (canEditLabels) {
        showEditLabelPrompt();
      }
    }, [canEditLabels, showEditLabelPrompt]);

    const onEditTitleClicked = useCallback(() => {
      toggleEditingName(true);
      closeOptionsMenu();
    }, [toggleEditingName, closeOptionsMenu]);

    return (
      <>
        <div className={Styles.workspaceCardContainer}>
          <div
            data-workspace-name={workspaceDetails.workspaceName}
            className={`${Styles.workspaceCard} ${
              applyOpacityStyle ? Styles.inactive : ""
            }`}
            title={Localized.string(
              "WORKSPACE_CARD.WORKSPACE_CHANGES_SINCE",
              workspaceDetails.workspaceName,
              mapName(workspaceDetails.module.name),
              isTemplate
                ? strings.TEMPLATE
                : showWorkspaceModifiedNotification
                ? strings.CHANGES_SINCE
                : strings.NO_CHANGES_SINCE
            )}
          >
            <div
              data-test-id="workspace-tile"
              className={allowFullScreen ? "" : Styles.workspaceTile}
              /* We use two methods here because onClick allows to open EditLabelDialog while clicking on workspace label,
                 instead of opening Workspace directly. However, this handler does not capture mouse middle click, blocking
                 opening the workspace with it. onMouseDown was used explicitly to handle the middle click.
               */
              onClick={openWorkspaceLink}
              onMouseDown={(e) =>
                e.button === 1 ? openWorkspaceLink(e) : null
              }
            >
              {workspaceDetails.isLive ? (
                <LiveWorkspacePreview {...props} />
              ) : (
                <>
                  <LazyLoad>
                    <CustomWorkspacePreview
                      {...props}
                      setIsVisible={setIsVisible}
                    />
                  </LazyLoad>
                  {!isVisible && <DefaultWorkspacePreview {...props} />}
                </>
              )}
            </div>
            <WorkspaceCardThumbnail
              config={config}
              isEditingWorkspaceName={isEditingWorkspaceName}
              isLimited={isLimited}
              isOptionsMenuOpen={isOptionsMenuOpen}
              openMoreOptions={openMoreOptions}
              outsideElementRef={outsideElementRef}
              project={project}
              showWorkspaceModifiedNotification={
                showWorkspaceModifiedNotification
              }
              toggleEditingName={toggleEditingName}
              updateWorkspaceName={updateWorkspaceName}
              workspaceDetails={workspaceDetails}
            />
          </div>
          {isOptionsMenuOpen && (
            <div ref={ref} className={Styles.moreOptionsContainer}>
              <MoreOptions
                shareClicked={showShareModal}
                workspaceInfoClicked={showInfoModal}
                hideClicked={showHideWorkspacePrompt}
                unhideClicked={unhideWorkspace}
                leaveClicked={showLeaveWorkspacePrompt}
                deleteClicked={showDeleteWorkspacePrompt}
                duplicateClicked={showDuplicateWorkspacePrompt}
                editLabelClicked={onEditLabelClicked}
                canHide={!!workspaceDetails.isFromMyWorkspaces}
                canLeave={!!workspaceDetails.canLeave}
                canDelete={
                  !!workspaceDetails.isAdmin && !workspaceDetails.templateId
                } // TODO: Remove second condition in #13427
                canEditLabels={!!workspaceDetails.isAdmin}
                canDuplicate={
                  workspaceDetails.workspaceType === WorkspaceType.HOYLU &&
                  !workspaceDetails.templateId
                }
                assignProjectClicked={showAssignProjectModal}
                canAssignProject={
                  showProjectOptions &&
                  !workspaceDetails.containerId &&
                  hasAssignableProjects &&
                  !!workspaceDetails.isAdmin
                }
                removeFromProjectClicked={showRemoveFromProjectPrompt}
                canRemoveProject={
                  showProjectOptions &&
                  !!workspaceDetails.containerId &&
                  !!project?.userPermissions.includes("RemoveProjectWorkspaces")
                }
                editTitleClicked={onEditTitleClicked}
                canEditTitle={!!workspaceDetails.isAdmin}
                downloadClicked={showDownloadModal}
                canDownload={isWorkspaceExportEnabled}
                isHidden={!!workspaceDetails.isHidden}
              />
            </div>
          )}
        </div>
      </>
    );
  },
  shouldRerender
);

const mapStateToProps = (state: RootState, ownProps: any) => ({
  config: state.context.config,
  showProjectOptions: state.context.config.featureFlags.projectLicensing,
  selectedWorkspace: getSelectedWorkspaceDetails(state),
  activeDashboardOption: state.context.workspaces.activeOption,
  userEmail: state.context.user.profile.email,
  strings: Localized.object("WORKSPACE_CARD"),
  freeAccount: state.context.user.licenseInfo?.type === LicenseType.Free,
  token: state.context.user.token,
  showWorkspaceWeight: state.context.config.featureFlags.showWorkspaceWeight,
  project: getProjectFromIdFillingUnkownOnes(
    state,
    ownProps?.workspaceDetails?.containerId
  ),
  hasAssignableProjects: !!state.context.workspaces.assignableProjects?.length,
  enableWorkspaceTemplates:
    state.context.config.featureFlags.workspaceTemplates,
  isWorkspaceExportEnabled: enableWorkspaceExport(state),
});

const mapDispatchToProps = {
  editWorkspace: workspaceActions.editWorkspace,
  updateWorkspaceName: workspaceActions.updateWorkspaceName.request,
  activateDashboardOption: workspaceActions.activateDashboardOption,
  createWorkspaceFromTemplate: workspaceActions.createWorkspaceFromTemplate,
  cancelDashboardOption: workspaceActions.cancelDashboardOption,
  setLastAccess: workspaceActions.setLastAccess,
  removeWorkspaceFromProject:
    workspaceActions.removeWorkspaceFromProject.request,
  toggleWorkspaceFavorite: workspaceActions.markWorkspaceAsFavorite,
  toggleWorkspaceVisibility: workspaceActions.toggleWorkspaceVisibility.request,
};

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: {
    workspaceDetails: WorkspaceDetails;
    editWorkspaceAction?: (workspaceId: string) => void;
    onProjectLabelClick?: (project: Project) => void;
    isFavorite?: boolean;
    order?: number;
    hide?: boolean;
  }
) => {
  const {
    selectedWorkspace,
    activeDashboardOption,
    freeAccount,
    ...filteredStateProps
  } = stateProps;
  return {
    ...ownProps,
    ...filteredStateProps,
    canEditLabels: ownProps.workspaceDetails.isAdmin,
    isOptionsMenuOpen:
      activeDashboardOption ===
        (ownProps.isFavorite
          ? DashboardOption.OPTIONS_FAVORITE
          : DashboardOption.OPTIONS) &&
      selectedWorkspace?.workspaceId === ownProps.workspaceDetails.workspaceId,
    updateWorkspaceName: dispatchProps.updateWorkspaceName,
    closeOptionsMenu: dispatchProps.cancelDashboardOption,
    setWorkspaceFavorite: () =>
      dispatchProps.toggleWorkspaceFavorite(
        ownProps.workspaceDetails.workspaceId
      ),
    toggleOptionsMenu: (optionType: DashboardOption) =>
      dispatchProps.activateDashboardOption({
        optionType,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      }),
    showDuplicateWorkspacePrompt: () =>
      dispatchProps.activateDashboardOption({
        optionType: DashboardOption.DUPLICATE,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      }),
    showCreateFromTemplatePrompt: () =>
      dispatchProps.createWorkspaceFromTemplate(ownProps.workspaceDetails),
    showHideWorkspacePrompt: () => {
      dispatchProps.activateDashboardOption({
        optionType: DashboardOption.HIDE,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      });
    },
    unhideWorkspace: () =>
      dispatchProps.toggleWorkspaceVisibility(ownProps.workspaceDetails),
    showLeaveWorkspacePrompt: () =>
      dispatchProps.activateDashboardOption({
        optionType: DashboardOption.LEAVE,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      }),
    showDeleteWorkspacePrompt: () =>
      dispatchProps.activateDashboardOption({
        optionType: DashboardOption.DELETE,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      }),
    showEditLabelPrompt: () =>
      dispatchProps.activateDashboardOption({
        optionType: DashboardOption.ADD_LABEL,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      }),
    showShareModal: () =>
      dispatchProps.activateDashboardOption({
        optionType: DashboardOption.SHARE,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      }),
    showInfoModal: () =>
      dispatchProps.activateDashboardOption({
        optionType: DashboardOption.INFO,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      }),
    showAssignProjectModal: () =>
      dispatchProps.activateDashboardOption({
        optionType: DashboardOption.ASSIGN_PROJECT,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      }),
    showRemoveFromProjectPrompt: () =>
      dispatchProps.activateDashboardOption({
        optionType: DashboardOption.REMOVE_FROM_PROJECT,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      }),
    showDownloadModal: () =>
      dispatchProps.activateDashboardOption({
        optionType: DashboardOption.DOWNLOAD,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      }),
    openWorkspace: (w: WorkspaceDetails, newTab: boolean) => {
      if (
        (w.workspaceType === WorkspaceType.HOYLU ||
          w.workspaceType === WorkspaceType.EXPERIMENTAL) &&
        !newTab
      ) {
        if (ownProps.editWorkspaceAction) {
          ownProps.editWorkspaceAction(w.workspaceId);
        } else {
          dispatchProps.editWorkspace({ ...w, lastAccess: new Date() });
        }
      } else {
        dispatchProps.setLastAccess({
          workspaceId: w.workspaceId,
          lastAccess: new Date(),
        });
        window.open(w.workspaceId, w.workspaceId);
      }
    },
  };
};

export type WorkspaceCardProps = ReturnType<typeof mergeProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(WorkspaceCard);
