import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { decode } from "html-entities";
import { v4 as uuidv4 } from "uuid";
import { ShareSection } from "./ShareSection";
import { ShareSectionHeader } from "./ShareSectionHeader";
import {
  Button,
  Dropdown,
  Option,
  TextArea,
  TextInput,
  useValidatedState,
} from "@hoylu/client-common";
import SimpleWorkspaceCard from "../../cards/SimpleWorkspaceCard";
import { useDispatch, useSelector } from "react-redux";
import { Localized } from "../../../strings";
import { RootState } from "typesafe-actions";
import {
  cancelDashboardOption,
  editThumbnail,
} from "../../../state/workspaces/workspaces.actions";
import {
  createTemplate,
  fetchTemplateCategories,
  updateTemplateDetails,
} from "../../../state/templates/templates.actions";
import { TemplateCategoryState } from "../../../state/templates/types";
import { WorkspaceDetails } from "../../../state/workspaces/types";
import * as workspaceActions from "../../../state/workspaces/workspaces.actions";
import { DashboardOption } from "../../../state/workspaces/DashboardOption";
import {
  getSelectedTemplateDetails,
  isLoadingTemplate,
  isUpdatedTemplate,
  templateCategories,
} from "../../../state/templates/templates.selectors";
import { InfoSection } from "./ShareType";
import { MediumRippleSpinner } from "../../RippleSpinner";
import {
  ORGANIZATION_CATEGORY_ID,
  QUICK_ACCESS_CATEGORY_ID,
} from "../../../services/templates/types";
import Styles from "./TemplateInfoSection.module.css";
import { RemoveButton } from "../modalElements/RemoveButton";

export type TemplateInfoSectionProps = {
  workspaceDetails: WorkspaceDetails;
  isOrgAdmin: boolean;
  handleChangeSectionType: Dispatch<SetStateAction<InfoSection>>;
  initiallyOpen: boolean;
};

enum ActionType {
  EDIT = "edit",
  SAVE = "save",
  PUBLISH = "publish",
}

export const TemplateInfoSection = ({
  workspaceDetails,
  isOrgAdmin,
  handleChangeSectionType,
  initiallyOpen,
}: TemplateInfoSectionProps) => {
  const dispatch = useDispatch();
  const strings = Localized.object("SHARE_WORKSPACE_DIALOG");
  const isAdmin = !!workspaceDetails.isAdmin;
  const isAlreadyPublished = !!workspaceDetails.templateId;

  // Selectors
  const categories = useSelector((state: RootState) =>
    templateCategories(state)
  );
  const enableFavouritesCategory = useSelector(
    (state: RootState) =>
      state.context.config.featureFlags.enableFavouritesCategory
  );
  const templateDetails = useSelector((state: RootState) =>
    getSelectedTemplateDetails(state, workspaceDetails.templateId!)
  );
  const isUpdated = useSelector((state: RootState) => isUpdatedTemplate(state));
  const isLoadingTemplateInfo = useSelector((state: RootState) =>
    isLoadingTemplate(state)
  );
  const showTemplateCreationUI = useSelector(
    (state: RootState) =>
      state.context.config.featureFlags.showTemplateCreationUI
  );
  const showOrgTemplateCreationUI = useSelector(
    (state: RootState) => state.context.config.featureFlags.orgTemplates
  );

  // Validators
  const isRequired = useCallback(
    (value?: string): string | undefined => {
      if (value && value.length) return;
      return strings.MANDATORY_FIELD;
    },
    [strings.MANDATORY_FIELD]
  );
  const isWithinCharLimit = useCallback(
    (value?: string): string | undefined => {
      if (value && value.length < 200) return;
      return strings.CHARACTER_LIMIT;
    },
    [strings.CHARACTER_LIMIT]
  );

  // States
  const [templateName, setTemplateName, templateNameError] = useValidatedState<
    string | undefined
  >(templateDetails?.name ?? workspaceDetails.workspaceName, [
    isRequired,
    isWithinCharLimit,
  ]);
  const [templateDescription, setTemplateDescription] = useState(
    templateDetails?.description
  );
  const [templateCategoryIds, setTemplateCategory] = useState<Option<string>[]>(
    []
  );
  const [inEditMode, setInEditMode] = useState(false);
  const [isOpenSection, setIsOpenSection] = useState(initiallyOpen);

  const actionType = useMemo(() => {
    if (isAlreadyPublished) {
      return ActionType.EDIT;
    } else if (templateCategoryIds.length > 0) {
      return ActionType.PUBLISH;
    } else {
      return ActionType.SAVE;
    }
  }, [isAlreadyPublished, templateCategoryIds]);

  const categoryDropdownOptions: Option<string>[] = useMemo(
    () =>
      categories
        // We do not need "Organization" category in the dropdown in any case as it is added automatically on the be side.
        // Hoylu users can additionally add other public categories
        .filter((category) => category.categoryId !== ORGANIZATION_CATEGORY_ID)
        .filter((category) =>
          enableFavouritesCategory
            ? category
            : category.categoryId !== QUICK_ACCESS_CATEGORY_ID
        )
        .map(({ name, categoryId }: TemplateCategoryState) => ({
          label: decode(name),
          value: categoryId,
        })),
    [categories]
  );

  const submitBtnLabel =
    templateCategoryIds.length > 0 && !isAlreadyPublished
      ? strings.PUBLISH
      : strings.SAVE;

  const toggleOnHeaderClick = () => {
    setIsOpenSection((prevState) => !prevState);
  };

  const close = () =>
    inEditMode
      ? isAlreadyPublished
        ? setInEditMode(!inEditMode)
        : handleChangeSectionType(InfoSection.WORKSPACE)
      : dispatch(cancelDashboardOption());

  const submit = () => {
    if (templateNameError) return;

    const templateId = uuidv4();
    const categoryIds = templateCategoryIds.map((c) => c.value);
    const templateData = {
      workspaceId: workspaceDetails.workspaceId,
      name: templateName!,
      description: templateDescription,
    };

    switch (actionType) {
      case ActionType.PUBLISH:
        dispatch(
          createTemplate.request({ templateId, categoryIds, ...templateData })
        );
        break;

      case ActionType.EDIT:
        dispatch(
          updateTemplateDetails.request({
            templateId:
              workspaceDetails.templateId! ?? templateDetails?.templateId!,
            details: templateData,
            originalTemplateIds: templateDetails?.categoryIds!,
            updatedTemplatesIds: categoryIds,
          })
        );
        break;

      case ActionType.SAVE:
        dispatch(createTemplate.request({ templateId, ...templateData }));
        break;
    }
  };

  const showDeleteTemplatePrompt = () =>
    dispatch(
      workspaceActions.activateDashboardOption({
        optionType: DashboardOption.DELETE_TEMPLATE,
        workspaceId: workspaceDetails?.workspaceId,
      })
    );

  useEffect(() => {
    dispatch(fetchTemplateCategories.request());
  }, [dispatch]);

  useEffect(() => {
    if (isUpdated || isAlreadyPublished) {
      setInEditMode(false);
    } else {
      setInEditMode(true);
    }
  }, [isUpdated, isAlreadyPublished]);

  useEffect(() => {
    if (categories.length > 0) {
      const selectedCategoriesAsOptions = categories
        .filter((c) => templateDetails?.categoryIds.includes(c.categoryId))
        .map((c) => ({ label: c.name, value: c.categoryId }));

      setTemplateCategory(selectedCategoriesAsOptions);
    }
  }, [categories.length]);

  if (isLoadingTemplateInfo) {
    return (
      <div className={Styles.spinnerContainer}>
        <MediumRippleSpinner />
      </div>
    );
  }

  return (
    <ShareSection testId="template-info">
      <ShareSectionHeader
        sectionIcon={"hoylu-ui-icons-section-template"}
        sectionTitle={strings.TEMPLATE_INFO}
        isSectionVisible={isOpenSection}
        onHeaderClick={toggleOnHeaderClick}
        alwaysShowIcons={true}
      />
      {isOpenSection && !!workspaceDetails && (
        <div className={Styles.templateInfoSection}>
          <div className={Styles.templateInfo}>
            <SimpleWorkspaceCard workspaceDetails={workspaceDetails}>
              {isAdmin ? (
                <div
                  className={Styles.editThumbnail}
                  onClick={() => dispatch(editThumbnail({ flag: true }))}
                >
                  <span className={"hoylu-ui-icons-edit"} />
                  <span>{strings.CHANGE_THUMBNAIL}</span>
                </div>
              ) : (
                <span
                  className={Styles.truncate}
                  title={workspaceDetails.workspaceName}
                >
                  {workspaceDetails.workspaceName}
                </span>
              )}
            </SimpleWorkspaceCard>
            <div className={Styles.inputs}>
              <TextInput
                label={strings.TEMPLATE_TITLE}
                value={templateName}
                onChange={setTemplateName}
                error={templateNameError}
                disabled={!inEditMode}
              />
              <TextArea
                label={strings.TEMPLATE_DESCRIPTION_TITLE}
                value={templateDescription}
                watermark={strings.TEMPLATE_DESCRIPTION_PLACEHOLDER}
                onChange={setTemplateDescription}
                disabled={!inEditMode}
                textareaHeight={"4rem"}
              />
              {showTemplateCreationUI && (
                <Dropdown
                  showLabel
                  title={strings.TEMPLATE_CATEGORY_TITLE}
                  options={categoryDropdownOptions}
                  values={templateCategoryIds}
                  onChange={setTemplateCategory}
                  useMultiSelect={true}
                  enableReferenceGuard={true}
                  dataTestId={"categories-list"}
                  readonly={!inEditMode}
                />
              )}
            </div>
          </div>
          <div className={Styles.footer}>
            <div>
              {inEditMode && isAlreadyPublished && (
                <RemoveButton
                  onClick={showDeleteTemplatePrompt}
                  text={strings.REMOVE_TEMPLATE}
                />
              )}
            </div>

            {isAdmin && (
              <div>
                <Button
                  type={"secondary"}
                  toolTip={strings.CANCEL}
                  onClick={close}
                >
                  {strings.CANCEL}
                </Button>
                {showTemplateCreationUI ||
                (isOrgAdmin && showOrgTemplateCreationUI) ? (
                  inEditMode ? (
                    <Button
                      type={"primary"}
                      toolTip={submitBtnLabel}
                      onClick={submit}
                    >
                      {submitBtnLabel}
                    </Button>
                  ) : (
                    <Button
                      type={"primary"}
                      toolTip={strings.EDIT}
                      onClick={() => setInEditMode(true)}
                    >
                      {strings.EDIT}
                    </Button>
                  )
                ) : null}
              </div>
            )}
          </div>
        </div>
      )}
    </ShareSection>
  );
};
