import { useDispatch, useSelector } from "react-redux";
import { getWaitingToLoadProjectID } from "../../state/workspaces/workspaces.selector";
import React, { useEffect, useState } from "react";
import { Localized } from "../../strings";
import { queueProjectLoading } from "../../state/workspaces/workspaces.actions";
import {
  getAllProjects,
  getSelectedProject,
} from "../../state/workspaces/projects.selector";
import { ProjectSelectionHandler } from "./ProjectNavigationDropdown";
import Styles from "./ProjectNavigationInformation.module.css";

type ProjectNavigationInformationProps = {
  handleProjectSelected: ProjectSelectionHandler;
};

export const ProjectNavigationInformation = ({
  handleProjectSelected,
}: ProjectNavigationInformationProps) => {
  const waitingProjectId = useSelector(getWaitingToLoadProjectID);
  const allProjects = useSelector(getAllProjects);
  const selectedProject = useSelector(getSelectedProject);
  const dispatch = useDispatch();
  const [noAccessToProject, setNoAccessToProject] = useState(false);
  const strings = Localized.object("HEADER");

  useEffect(() => {
    if (!waitingProjectId || !allProjects) return;

    const project = allProjects?.find(
      (project) => project.id === waitingProjectId
    );

    if (project) {
      setNoAccessToProject(false);
      dispatch(queueProjectLoading(undefined));
      handleProjectSelected(project);
    } else if (allProjects && waitingProjectId) {
      dispatch(queueProjectLoading(undefined));
      setNoAccessToProject(true);

      if (selectedProject) {
        handleProjectSelected(selectedProject);
      }
    }
  }, [waitingProjectId, allProjects]);

  useEffect(() => {
    selectedProject && noAccessToProject && setNoAccessToProject(false);
  }, [selectedProject]);

  return (
    <>
      {noAccessToProject && (
        <div className={Styles.projectInformation}>{strings.NO_ACCESS}</div>
      )}
    </>
  );
};
