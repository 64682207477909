import React, { CSSProperties, useEffect, useMemo } from "react";
import { connect, Provider } from "react-redux";
import { Store } from "redux";
import { NotificationsContextProvider } from "@hoylu/notifications";
import { RootState } from "typesafe-actions";
import { login } from "../state/user/user.actions";
import LoginPrompt from "./prompts/LoginPrompt";
import { isWorkspaceFullscreen } from "../state/workspaces/workspaces.selector";
import Modals from "./modals";
import WorkspaceContent from "./WorkspaceContent";
import BetaDisabledContent from "./BetaDisabledContent";
import AccountVerificationError from "./AccountVerificationError";
import SimplifiedHeader from "./Header/SimplifiedHeader";
import { AppMode } from "../state/mode/mode.reducer";
import { Localized } from "../strings";
import { WorkspaceLoading } from "./WorkspaceLoading";
import { getSelectedProjectColor } from "../state/workspaces/projects.selector";
import { hexToRgba } from "../utils/hexToRgba";
import { Root } from "react-dom/client";
import Styles from "./App.module.css";
import Scrollbar from "@hoylu/client-common/dist/esm/assets/css.modules/scrollbar.module.css";
import { notificationsService } from "../state/config/config.selector";
import { userToken } from "../state/user/user.selector";
import {
  THEME_LIGHT,
  ThemeContext,
  useThemeStorage,
} from "@hoylu/client-common";
import { APP_NODE_ID } from "../app.defaults";

const App: React.FC<AppProps> = ({
  isLoggedIn,
  showWorkspaceLoadingOverlay,
  isWorkspaceFullscreen,
  projectThemeColor,
  appMode,
  notificationServiceUrl,
  token,
}) => {
  const themeContext = useThemeStorage(THEME_LIGHT);
  const strings = Localized.object("APP");
  // We aim to dynamically adjust the app's content background gradient according to the chosen project color.
  const gradient = useMemo(
    () =>
      projectThemeColor
        ? {
            backgroundImage: `linear-gradient(to bottom,
          ${hexToRgba(projectThemeColor)} 0%,
          var(--gradient-from-color-theme) 50%,
          var(--gradient-to-color-theme) 100%
        )`,
          }
        : {
            backgroundImage:
              "linear-gradient(to bottom, var(--theme-gradient))",
          },
    [projectThemeColor]
  ) as CSSProperties;

  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];
    if (isWorkspaceFullscreen) {
      html.className = Styles.htmlHidden;
    } else {
      html.className = Styles.htmlAuto;
    }
  }, [isWorkspaceFullscreen]);

  const appRootClassName = useMemo(() => {
    const className = [Styles.app, themeContext.theme, Scrollbar.default];
    if (isWorkspaceFullscreen || isLoggedIn === "UNKNOWN") {
      className.push(Styles.appHidden);
    }
    return className.join(" ");
  }, [isWorkspaceFullscreen, isLoggedIn, themeContext.theme]);

  if (appMode === AppMode.BETA_NOT_ALLOWED) return <BetaDisabledContent />;

  if (
    [
      AppMode.NOT_VERIFIED_POLICY_RESTRICTED,
      AppMode.SKIPPABLE_VERIFIED_POLICY_RESTRICTED,
    ].includes(appMode)
  )
    return <AccountVerificationError />;

  return (
    <ThemeContext.Provider value={themeContext}>
      <NotificationsContextProvider
        serverUrl={notificationServiceUrl}
        token={token}
      >
        <div
          id={APP_NODE_ID}
          data-test-id={APP_NODE_ID}
          className={appRootClassName}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => e.preventDefault()}
          style={gradient}
        >
          <Modals />
          {isLoggedIn === "UNKNOWN" && (
            <>
              <SimplifiedHeader />
              <div className={Styles.loadingWorkspace}>
                {strings.LOADING_YOUR_WORKSPACE}
              </div>
            </>
          )}
          {isLoggedIn !== "UNKNOWN" &&
            (isLoggedIn ? <WorkspaceContent /> : <LoginPrompt />)}
          {showWorkspaceLoadingOverlay && <WorkspaceLoading />}
        </div>
      </NotificationsContextProvider>
    </ThemeContext.Provider>
  );
};

const mapStateToProps = (state: RootState) => ({
  appMode: state.mode,
  isLoggedIn: state.context.user.isLoggedIn,
  isWorkspaceFullscreen: isWorkspaceFullscreen(state),
  projectThemeColor: getSelectedProjectColor(state),
  showWorkspaceLoadingOverlay:
    (!!state.context.workspaces.waitingToEditID ||
      state.context.workspaces.isCreatingNewWorkspaceUrl) &&
    (state.mode === AppMode.LOADING_DASHBOARD ||
      state.mode === AppMode.DASHBOARD),
  notificationServiceUrl: notificationsService(state),
  token: userToken(state),
});

const mapDispatchToProps = {
  login: login.request,
};

type AppProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export function renderApp(rootElement: Root, reduxStore: Store) {
  rootElement.render(
    <Provider store={reduxStore}>
      <ConnectedApp />
    </Provider>
  );
}

export default renderApp;
