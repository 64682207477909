import React from "react";
import { Button } from "@hoylu/client-common";
import Styles from "./RemoveButton.module.css";

export type RemoveButtonProps = {
  text: string;
  onClick: () => void;
};

export const RemoveButton = ({ text, onClick }: RemoveButtonProps) => {
  return (
    <Button
      type={"secondary"}
      classNames={[Styles.removeButton]}
      onClick={onClick}
    >
      <div className={"hoylu-ui-icons-incomplete"} />
      {`${text}...`}
    </Button>
  );
};
