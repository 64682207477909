import LazyLoad from "react-lazy-load";
import React, { useState } from "react";
import { RootState } from "typesafe-actions";
import { connect } from "react-redux";
import { WorkspaceDetails } from "../../state/workspaces/types";
import * as workspaceActions from "../../state/workspaces/workspaces.actions";
import { DashboardOption } from "../../state/workspaces/DashboardOption";
import { getProjectFromIdFillingUnkownOnes } from "../../state/workspaces/projects.selector";
import CustomWorkspacePreview, {
  CustomWorkspacePreviewProps,
} from "./WorkspaceCard/CustomWorkspacePreview";
import DefaultWorkspacePreview from "./WorkspaceCard/DefaultWorkspacePreview";
import Styles from "./SimpleWorkspsaceCard.module.css";

const SimpleWorkspaceCard = ({
  children,
  ...props
}: React.PropsWithChildren<
  Omit<CustomWorkspacePreviewProps, "setIsVisible">
>) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className={Styles.simpleWorkspaceCard}>
      <div className={Styles.thumbnailContainer}>
        <LazyLoad>
          <CustomWorkspacePreview {...props} setIsVisible={setIsVisible} />
        </LazyLoad>
        {!isVisible && <DefaultWorkspacePreview {...props} />}
      </div>
      <div className={Styles.thumbnailFooter} style={{ maxWidth: "200px" }}>
        {children}
      </div>
    </div>
  );
};

const mapStateToProps = (
  state: RootState,
  ownProps: { workspaceDetails: WorkspaceDetails }
) => ({
  token: state.context.user.token,
  showProjectOptions: state.context.config.featureFlags.projectLicensing,
  showWorkspaceWeight: state.context.config.featureFlags.showWorkspaceWeight,
  project: getProjectFromIdFillingUnkownOnes(
    state,
    ownProps?.workspaceDetails?.containerId
  ),
});

const mapDispatchToProps = {
  activateDashboardOption: workspaceActions.activateDashboardOption,
  toggleWorkspaceFavorite: workspaceActions.markWorkspaceAsFavorite,
};

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: React.PropsWithChildren<{
    workspaceDetails: WorkspaceDetails;
  }>
) => {
  return {
    ...stateProps,
    ...ownProps,
    showEditLabelPrompt: () =>
      dispatchProps.activateDashboardOption({
        optionType: DashboardOption.ADD_LABEL,
        workspaceId: ownProps.workspaceDetails.workspaceId,
      }),
    setWorkspaceFavorite: () =>
      dispatchProps.toggleWorkspaceFavorite(
        ownProps.workspaceDetails.workspaceId
      ),
    canEditLabels: ownProps.workspaceDetails.isAdmin,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SimpleWorkspaceCard);
