import React, { useEffect } from "react";
import { RootState } from "typesafe-actions";
import {
  addWorkspaceToProject,
  assignableProjects,
  cancelDashboardOption,
} from "../../state/workspaces/workspaces.actions";
import { connect } from "react-redux";
import { getSelectedWorkspaceDetails } from "../../state/workspaces/workspaces.selector";
import { getAssignableProjects } from "../../state/workspaces/projects.selector";
import { Localized } from "../../strings";
import { Modal } from "./Modal";
import { Project, ProjectStatus } from "../../state/workspaces/types";
import { hiconToHoyluUiIconsCentered } from "../../utils/icon.map";
import { ProjectStatuses } from "../../state/workspaces/workspaces.reducer";
import { hexToRgba } from "../../utils/hexToRgba";
import Styles from "./AssignProjectDialog.module.css";
import {
  DraggableWindowHeader,
  Dropdown,
  Option,
  SearchEngineType,
} from "@hoylu/client-common";
import { ProjectOptionRenderer } from "../Header/ProjectNavigationDropdown";
import { addPrefixWhenProjectIsInactive } from "../../utils/add.prefix.when.project.is.inactive";

const EMPTY_PROJECT: Project = {
  id: "",
  icon: "",
  name: "",
  status: null,
  color: "",
  userPermissions: [],
  hasAdmin: false,
};

export function ProjectSelectorLabel(
  icon: string,
  name: string,
  status: ProjectStatus | null,
  color?: string
) {
  const isProjectInactive = status === ProjectStatuses.Inactive;
  const colorVariant = isProjectInactive
    ? hexToRgba(color ?? "", 0.5, "#716C83")
    : color;

  return (
    <div className={Styles.projectSelector}>
      <div
        style={{ color: colorVariant }}
        className={`${
          hiconToHoyluUiIconsCentered(icon) || "hoylu-ui-icons-box"
        } ${Styles.projectIcon}`}
      />
      <div
        className={`${Styles.projectName} ${
          isProjectInactive ? Styles.inactive : ""
        }`}
      >
        {name}
      </div>
    </div>
  );
}

export const AssignProjectDialog: React.FC<ProjectProps> = ({
  cancelDashboardOption,
  workspaceDetails,
  addToProject,
  assignableProjects,
  updateAssignableProjects,
}) => {
  const strings = Localized.object("ASSIGN_PROJECT_DIALOG");

  // update assignableProjects on load (or when the workspace details change)
  useEffect(() => {
    updateAssignableProjects();
  }, [workspaceDetails]);

  const DefaultOption: Option<Project> = {
    label: strings.SELECT_A_PROJECT,
    value: EMPTY_PROJECT,
  };

  const setProject = (options: Option<Project>[]) => {
    const project = options[0]?.value;
    if (!project) return;
    if (!workspaceDetails) return;
    addToProject({
      workspaceId: workspaceDetails.workspaceId,
      projectId: project.id,
    });
    cancelDashboardOption();
  };

  const options: Option<Project>[] = assignableProjects.map((p) => ({
    label: addPrefixWhenProjectIsInactive(p),
    value: p,
  }));

  return (
    <Modal
      handleOutsideClick={cancelDashboardOption}
      /* need this for the dropdown to be rendered visually outside of the box */
      allowContentToOverflow={true}
    >
      <div
        className={Styles.assignProject}
        data-test-id="assign-project-dialog"
      >
        <DraggableWindowHeader
          title={strings.ASSIGN_TO_PROJECT}
          onClose={cancelDashboardOption}
        />
        <div className={Styles.assignProjectDescription}>
          {strings.ASSIGN_PROJECT_DESCRIPTION}
        </div>
        <Dropdown
          title={""}
          values={[DefaultOption]}
          options={options}
          onChange={(option) => setProject(option)}
          optionRenderer={ProjectOptionRenderer}
          searchEngineType={SearchEngineType.FUZZY}
          allowReset={false}
        />
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  cancelDashboardOption,
  addToProject: addWorkspaceToProject.request,
  updateAssignableProjects: assignableProjects.request,
};

const mapStateToProps = (state: RootState) => ({
  workspaceDetails: getSelectedWorkspaceDetails(state),
  assignableProjects: getAssignableProjects(state),
});

type ProjectProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignProjectDialog);
