import React, { useMemo } from "react";
import { Localized } from "../../strings";
import { ProjectSelectorLabel } from "../modals/AssignProjectDialog";
import { Project, WorkspaceProjects } from "../../state/workspaces/types";
import Styles from "./ProjectNavigationDropdown.module.css";
import DefaultDropdownStyle from "@hoylu/client-common/dist/esm/assets/css.modules/default.dropdown.module.css";
import {
  Dropdown,
  Option,
  OptionRendererProps,
  SearchEngineType,
} from "@hoylu/client-common";
import { addPrefixWhenProjectIsInactive } from "../../utils/add.prefix.when.project.is.inactive";

// Escape value for Dropdown options
export const EmptyProject = "EmptyProject";
export const SelectProject = "SelectProject";

export type ProjectSelectionHandler = (project: Project) => void;

type ProjectNavigationDropdownProps = {
  allProjects: WorkspaceProjects;
  selectedProject: Project | null;
  handleProjectSelected: ProjectSelectionHandler;
  showProjects: boolean;
  allowCreatingProject: boolean;
};

export const ProjectNavigationDropdown: React.FC<ProjectNavigationDropdownProps> = ({
  allProjects,
  selectedProject,
  handleProjectSelected,
  showProjects,
  allowCreatingProject,
}) => {
  const strings = Localized.object("FILTER_BY_NAME");

  const DashboardOption: Option<Project> = {
    label: strings.DASHBOARD,
    value: {
      id: SelectProject,
      icon: "hoylu",
      name: strings.SELECT_PROJECT,
      status: null,
      color: "var(--global--notification--Color)",
      userPermissions: [],
      hasAdmin: false,
    },
  };

  const EmptyProjectOption: Option<Project> = {
    label: strings.NEW_PROJECT,
    value: {
      id: EmptyProject,
      icon: "plus",
      name: strings.NEW_PROJECT,
      status: null,
      color: "var(--global--accent--Color)",
      userPermissions: [],
      hasAdmin: false,
    },
  };

  const projectsDropdownOptions: Option<Project>[] = [
    DashboardOption,
    ...allProjects.map((p) => {
      return {
        label: addPrefixWhenProjectIsInactive(p),
        value: p,
      };
    }),
  ];

  if (allowCreatingProject) {
    projectsDropdownOptions.push(EmptyProjectOption);
  }

  const value = useMemo(() => {
    return (
      projectsDropdownOptions.find(
        ({ value }) => value.id === selectedProject?.id
      ) ?? DashboardOption
    );
  }, [selectedProject, projectsDropdownOptions]);

  if (!showProjects || !allProjects.length) {
    return null;
  }

  return (
    <div
      aria-label={strings.FILTER_BY_NAME}
      tabIndex={0}
      className={Styles.dropdownContainer}
    >
      <Dropdown
        onChange={(option) => handleProjectSelected(option[0]?.value)}
        title={strings.DASHBOARD}
        values={[value]}
        options={projectsDropdownOptions}
        allowReset={false}
        searchEngineType={SearchEngineType.FUZZY}
        valueRenderer={(value) => <ValueRenderer value={value} />}
        optionRenderer={ProjectOptionRenderer}
        classes={[
          DefaultDropdownStyle.dropdown,
          Styles.projectNavigationDropdown,
        ]}
        dropdownClasses={[
          DefaultDropdownStyle.dropDownPopUp,
          Styles.dropDownPopUp,
        ]}
      />
    </div>
  );
};

type ValueRendererProps<T> = { value: Option<T>[] };

const ValueRenderer = ({ value }: ValueRendererProps<Project>) => {
  const selectedOption = value[0];
  if (!selectedOption) return null;
  const project = selectedOption.value;

  return ProjectSelectorLabel(
    project.icon,
    project.name,
    project.status,
    project.color
  );
};

export const ProjectOptionRenderer = ({
  option,
  onClick,
  key,
}: OptionRendererProps<Project>) => {
  const project = option.value;
  return (
    <div key={key} className={Styles.option} onClick={onClick}>
      {ProjectSelectorLabel(
        project.icon,
        option.label,
        project.status,
        project.color
      )}
    </div>
  );
};

export default ProjectNavigationDropdown;
