import React, { useMemo } from "react";
import { FrameContexts } from "@microsoft/teams-js";
import WorkspacePreviewLabels from "./WorkspacePreviewLabels";
import { WorkspaceCardProps } from "./WorkspaceCard";
import Styles from "./LiveWorkspacePreview.module.css";

const LiveWorkspacePreview: React.FC<WorkspaceCardProps> = ({
  workspaceDetails,
  showEditLabelPrompt,
  canEditLabels,
  showProjectOptions,
  project,
  config,
  onProjectLabelClick,
}) => {
  const labelsAndPotentialAssignProject =
    project && showProjectOptions
      ? workspaceDetails.labels?.concat([project])
      : workspaceDetails.labels;
  const allowFullScreen = useMemo(
    () =>
      workspaceDetails.isFullscreen &&
      config.teamsFrame != FrameContexts.sidePanel,
    [workspaceDetails.isFullscreen, config.teamsFrame]
  );
  return (
    <>
      <iframe
        name={"iframes"}
        id={workspaceDetails.workspaceId}
        title={workspaceDetails.workspaceId}
        className={`${Styles.liveWorkspacePreview} ${
          allowFullScreen ? Styles.fullScreen : Styles.screen
        }`}
        src={workspaceDetails.workspaceUrl}
      />
      {!allowFullScreen && (
        <WorkspacePreviewLabels
          onProjectLabelClick={onProjectLabelClick}
          canEditLabels={!!canEditLabels}
          showEditLabelPrompt={showEditLabelPrompt}
          labels={labelsAndPotentialAssignProject}
          workspaceDetails={workspaceDetails}
        />
      )}
    </>
  );
};

export default LiveWorkspacePreview;
