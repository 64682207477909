import React, { CSSProperties } from "react";
import { Project, WorkspaceDetails } from "../../../state/workspaces/types";
import { useSelector } from "react-redux";
import { enableFavoritesWorkspaceSection } from "../../../state/config/config.selector";
import { Icon } from "@hoylu/client-common";
import WorkspacePreviewLabels from "./WorkspacePreviewLabels";
import { WorkspaceWeight } from "../WorkspaceWeight";
import { Localized } from "../../../strings";
import { mapName } from "../../CreateWorkspaceSection";
import { isWorkspaceLimited } from "../../../state/workspaces/workspaces.selector";
import { RootState } from "typesafe-actions";
import Styles from "./WorkspaceImagePreview.module.css";

type WorkspaceImagePreviewProps = {
  imgSrc: string;
  showWorkspaceWeight: boolean;
  setWorkspaceFavorite: () => void;
  canEditLabels: boolean;
  showEditLabelPrompt: () => void;
  project?: Project;
  onProjectLabelClick?: (project: Project) => void;
  workspaceDetails: WorkspaceDetails;
};

const WorkspaceImagePreview = ({
  imgSrc,
  project,
  setWorkspaceFavorite,
  canEditLabels,
  showEditLabelPrompt,
  onProjectLabelClick,
  showWorkspaceWeight,
  workspaceDetails,
}: WorkspaceImagePreviewProps): JSX.Element => {
  const isWorkspaceFavorite = !!workspaceDetails.isFavorite;
  const isLimited = useSelector((state: RootState) =>
    isWorkspaceLimited(state, workspaceDetails.workspaceId)
  );

  const showFavoritesStar = useSelector(enableFavoritesWorkspaceSection);
  const favIconStyles: CSSProperties = {
    position: "absolute",
    top: "0",
    right: "0.25rem",
    fontSize: "1.5rem",
    color: isWorkspaceFavorite ? "#fff" : "",
    // We have to reset the Icon's default paddings
    padding: "0",
  };

  return (
    <>
      <img
        className={Styles.workspaceImage}
        src={imgSrc}
        alt={Localized.string(
          "WORKSPACE_CARD.WORKSPACE_IMG_ALT",
          mapName(workspaceDetails.module.name)
        )}
      />
      {showFavoritesStar && (
        <Icon
          icon={
            isWorkspaceFavorite
              ? "hoylu-ui-icons-star-filled"
              : "hoylu-ui-icons-star"
          }
          style={favIconStyles}
          onClick={setWorkspaceFavorite}
        />
      )}
      <WorkspacePreviewLabels
        project={project}
        onProjectLabelClick={onProjectLabelClick}
        canEditLabels={canEditLabels}
        showEditLabelPrompt={showEditLabelPrompt}
        workspaceDetails={workspaceDetails}
      />
      {showWorkspaceWeight && (
        <WorkspaceWeight
          workspaceWeight={workspaceDetails.weight}
          inWarningMode={isLimited}
        />
      )}
    </>
  );
};

export default WorkspaceImagePreview;
