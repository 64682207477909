import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { ShareSection } from "./ShareSection";
import { ShareSectionHeader } from "./ShareSectionHeader";
import { Button, TextInput, useValidatedState } from "@hoylu/client-common";
import SimpleWorkspaceCard from "../../cards/SimpleWorkspaceCard";
import { useDispatch, useSelector } from "react-redux";
import * as workspaceActions from "../../../state/workspaces/workspaces.actions";
import { cancelDashboardOption } from "../../../state/workspaces/workspaces.actions";
import { getSelectedWorkspaceDetails } from "../../../state/workspaces/workspaces.selector";
import { Localized } from "../../../strings";
import { DashboardOption } from "../../../state/workspaces/DashboardOption";
import { RootState } from "typesafe-actions";
import { InfoSection } from "./ShareType";
import { editThumbnail } from "../../../state/workspaces/workspaces.actions";
import { getUserOrgId } from "../../../state/user/user.selector";
import Styles from "./WorkspaceInfoSection.module.css";
import { RemoveButton } from "../modalElements/RemoveButton";

export type WorkspaceInfoSectionProps = {
  initiallyOpen: boolean;
  isOrgAdmin: boolean;
  handleChangeSectionType: Dispatch<SetStateAction<InfoSection>>;
};

export const WorkspaceInfoSection = ({
  initiallyOpen,
  isOrgAdmin,
  handleChangeSectionType,
}: WorkspaceInfoSectionProps) => {
  const dispatch = useDispatch();
  const strings = Localized.object("SHARE_WORKSPACE_DIALOG");

  const isRequired = useCallback(
    (value?: string): string | undefined => {
      if (value && value.length) return;
      return strings.MANDATORY_FIELD;
    },
    [strings.MANDATORY_FIELD]
  );

  const isWithinCharLimit = useCallback(
    (value?: string): string | undefined => {
      if (value && value.length < 200) return;
      return strings.CHARACTER_LIMIT;
    },
    [strings.CHARACTER_LIMIT]
  );

  const workspaceDetails = useSelector(getSelectedWorkspaceDetails);
  const userOrgId = useSelector(getUserOrgId);
  const isWorkspaceOwnedByOrg = useMemo(
    () => workspaceDetails?.orgId === userOrgId,
    [workspaceDetails, userOrgId]
  );
  const showTemplateCreationUIFlag = useSelector(
    (state: RootState) =>
      state.context.config.featureFlags.showTemplateCreationUI
  );
  const showOrgTemplateCreationUIFlag = useSelector(
    (state: RootState) => state.context.config.featureFlags.orgTemplates
  );

  const showPublishAsTemplate =
    showTemplateCreationUIFlag || (isOrgAdmin && showOrgTemplateCreationUIFlag);

  const [isOpenSection, setIsOpenSection] = useState(initiallyOpen);
  const [workspaceName, setWorkspaceName, nameError] = useValidatedState<
    string | undefined
  >(workspaceDetails?.workspaceName, [isRequired, isWithinCharLimit]);
  const canSave = useMemo(
    () => workspaceDetails?.workspaceName != workspaceName && !nameError,
    [nameError, workspaceName, workspaceDetails?.workspaceName]
  );
  const isAdmin = !!workspaceDetails?.isAdmin;

  const saveName = useCallback(() => {
    if (workspaceDetails && workspaceName)
      dispatch(
        workspaceActions.updateWorkspaceName.request({
          workspaceId: workspaceDetails.workspaceId,
          name: workspaceName,
        })
      );
  }, [workspaceDetails?.workspaceId, workspaceName]);

  const showRemoveWorkspacePrompt = () =>
    dispatch(
      workspaceActions.activateDashboardOption({
        optionType: DashboardOption.REMOVE,
        workspaceId: workspaceDetails?.workspaceId,
      })
    );

  const toggleOnHeaderClick = () => {
    setIsOpenSection((prevState) => !prevState);
  };

  const close = () => dispatch(cancelDashboardOption());

  return (
    <ShareSection testId="workspace-info">
      <ShareSectionHeader
        sectionIcon={"hoylu-ui-icons-blank"}
        sectionTitle={strings.WORKSPACE_INFO}
        isSectionVisible={isOpenSection}
        onHeaderClick={toggleOnHeaderClick}
        alwaysShowIcons={true}
      />
      {isOpenSection && !!workspaceDetails && (
        <div className={Styles.workspaceInfoSection}>
          <div className={Styles.workspaceInfoEditContainer}>
            <SimpleWorkspaceCard workspaceDetails={workspaceDetails}>
              {workspaceDetails?.isAdmin ? (
                <div
                  className={Styles.workspaceCard}
                  onClick={() => dispatch(editThumbnail({ flag: true }))}
                >
                  <span className={"hoylu-ui-icons-edit"} />
                  <span>{strings.CHANGE_THUMBNAIL}</span>
                </div>
              ) : (
                <span
                  className={Styles.truncate}
                  title={workspaceDetails.workspaceName}
                >
                  {workspaceDetails.workspaceName}
                </span>
              )}
            </SimpleWorkspaceCard>
            <TextInput
              label={strings.WORKSPACE_TITLE}
              value={workspaceName}
              onChange={setWorkspaceName}
              error={nameError}
              style={{ flex: 1 }}
              disabled={!workspaceDetails.isAdmin}
            />
          </div>
          <div className={Styles.footer}>
            <RemoveButton
              onClick={showRemoveWorkspacePrompt}
              text={strings.REMOVE_WORKSPACE}
            />
            {isAdmin && (
              <div>
                <Button
                  type={"secondary"}
                  toolTip={strings.CANCEL}
                  onClick={close}
                >
                  {strings.CANCEL}
                </Button>
                {showPublishAsTemplate && (
                  <Button
                    type={"primary"}
                    toolTip={
                      !isWorkspaceOwnedByOrg
                        ? strings.PUBLISH_AS_TEMPLATE_NOT_ALLOWED
                        : strings.PUBLISH_AS_TEMPLATE
                    }
                    onClick={() =>
                      handleChangeSectionType(InfoSection.TEMPLATE)
                    }
                    disabled={!isWorkspaceOwnedByOrg}
                  >
                    {strings.PUBLISH_AS_TEMPLATE}
                  </Button>
                )}
                <Button
                  type={"primary"}
                  toolTip={"Save"}
                  onClick={saveName}
                  disabled={!canSave}
                >
                  {strings.SAVE}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </ShareSection>
  );
};
